@font-face {
  font-family: "Boxed";
  src: url("./fonts/BoxedSemibold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Boxed";
  src: url("./fonts/BoxedRegular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Boxed";
  src: url("./fonts/BoxedMedium.ttf");
  font-weight: 500;
}

html {
  background: linear-gradient(91.11deg, #15151b 38%, #1f1f2c 59.33%);
}
body {
  margin: 0;
  font-family: "Boxed", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  * {
    box-sizing: border-box;
  }
  background: linear-gradient(91.11deg, #15151b 38%, #1f1f2c 59.33%);
}
img {
  max-width: 100%;
}

.header {
  height: 98px;
  left: 0px;
  top: 0px;

  background: linear-gradient(91.11deg, #15151b 38%, #1f1f2c 59.33%);
  @media screen and (max-width: 768px) {
    height: 70px;
  }
  &-logo {
    flex: 1;
    @media screen and (max-width: 768px) {
      text-align: center;
      img {
        height: 37px;
      }
    }
  }
  &-content {
    display: flex;
    height: 98px;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
      height: 70px;
    }
  }
  &-menu {
    display: flex;
    align-items: center;
    &-item {
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      opacity: 0.7;
      padding: 12px 32px;
      color: white;
      text-transform: uppercase;
      text-decoration: none;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.container {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
}

.home {
  display: flex;
  height: calc(100vh - 100px);
  background: #15151b;
  min-height: 800px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  .home-wrap {
    width: 33.33%;
    flex: 1;
  }
  .home-item {
    height: 100%;
    text-align: center;
    padding-top: 36px;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    position: relative;
    display: block;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 11;
      background: rgba(0, 0, 0, 0.3);
      transition: all 0.1s ease-in;
      pointer-events: none;
    }

    &.active {
      &::after {
        background: transparent;
        backdrop-filter: blur(0px);
        transition: all 0.1s ease-in;
      }
      .person {
        transition: all 0.1s ease-in;
        width: 110%;
        margin-left: -5%;
      }
    }

    &-outdoor {
      background-image: url("./images/outdoor-bg.jpg");
      .person {
        position: absolute;
        bottom: 0;
        pointer-events: none;
      }
    }
    &-indoor {
      background-image: url("./images/indoor-bg.jpg");
      a {
        background: linear-gradient(360deg, #ff3434 -28.57%, #ffb59d 115.48%);
        box-shadow: 2px 7px 20px rgba(111, 0, 0, 0.35);
        border: 0;
        color: white;
      }
    }
    &-sportfi {
      background-image: url("./images/sportfi-bg.jpg");
      a {
        background: linear-gradient(360deg, #d3000d -11.9%, #ff778f 119.05%);
        box-shadow: 2px 7px 20px rgba(111, 0, 0, 0.35);
        border: 0;
        color: white;
      }
      .logo {
        margin-bottom: 22px;
      }
      .slogan {
        margin-bottom: 15px;
        img {
          height: 132px;
        }
      }
    }
  }
  .logo {
    text-align: center;
    margin-bottom: 36px;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .slogan {
    text-align: center;
    margin-bottom: 24px;
    img {
      height: 100px;
    }
  }
  a {
    padding: 10px 50px;
    height: 42px;
    border: 1px solid #ffdcc5;
    border-radius: 36px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    letter-spacing: 1.25px;
    color: #ffdcc5;
    text-decoration: none;
  }
  .person {
    transition: all 0.1s ease-in;
    position: absolute;
    bottom: 0;
    pointer-events: none;
    width: 100%;
    img {
      display: inline-block;
      &.mb {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    min-height: calc(100vh - 70px);
    height: auto;
    display: block;

    .home-wrap {
      width: 100%;
      flex: 1;
      padding: 8px 16px;
      max-width: 374px;
      height: 228px;
      margin-left: auto;
      margin-right: auto;
    }
    .home-item {
      border-radius: 12px;
      padding-top: 8%;
      height: 100%;
      &::after {
        content: unset;
      }
      &.active {
        .person {
          width: auto;
        }
      }

      &.home-item-outdoor {
        background-image: url("./images/outdoor-bg-mb.png");
      }
      &.home-item-indoor {
        background-image: url("./images/indoor-bg-mb.png");
        .person {
          height: 90%;
        }
      }
      &.home-item-sportfi {
        background-image: url("./images/sportfi-bg-mb.png");
        .slogan {
          height: 45%;
          margin-top: -10%;
          margin-left: -5%;
        }
      }
      .logo {
        text-align: left;
        padding-left: 6%;
        margin-bottom: 4%;
        height: 20%;
        img {
          height: 100%;
          width: auto;
        }
      }
      .slogan {
        padding-left: 10%;
        text-align: left;
        height: 25%;
        width: 70%;
        margin: 0;
        margin-bottom: 12px;
        img {
          height: 100%;
          width: auto;
        }
      }
      .link {
      }
      a {
        height: 26px;
        padding: 3px 25px;
        font-size: 10px;
      }
      .person {
        display: none;
      }
    }
  }
}

.banner {
  &-wrap {
    max-width: 1440px;
    margin: 0 auto;
  }

  &-container {
    background-image: url("./images/banner/bannerBgLg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 498px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  &-pot {
    margin-top: -14px;
    width: 212px;
    height: 211px;
  }

  &-title {
    margin-top: -41px;
    margin-bottom: 9px;
    width: 710px;
    height: 123px;
  }

  &-btn {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    cursor: pointer;
    &:active {
      transform: scale(0.9);
    }
  }

  .btn-play {
    margin-bottom: 14px;
    width: 280px;
    height: 74px;
  }

  .btn-join {
    width: 281px;
    height: 69px;
  }

  @media screen and (max-width: 768px) {
    background: #15151b;
    &-wrap {
      max-width: 374px;
      padding: 8px 16px;
      margin: 0 auto;
    }
    &-container {
      min-height: 212px;
      background-image: url("./images/banner/bannerBgSm.webp");
      align-items: flex-start;
      padding-left: 19px;
    }
    &-pot {
      margin-top: -5px;
      width: 98px;
      height: 96px;
      margin-left: -5px;
    }
    &-title {
      margin-top: -11px;
      width: 185px;
      height: 33px;
      margin-bottom: 5px;
    }
    .btn-play {
      margin-bottom: 0px;
      width: 123px;
      height: 32px;
    }
    .btn-join {
      width: 123px;
      height: 30px;
    }
  }
}
